.person {

  &__link {
    display: block;
  }

  &__row {
     margin-bottom: $space__ty;

    &--img {
      margin-bottom: $space__sm;
    }

    &--over-inline {
      overflow: hidden;      
      text-overflow: ellipsis;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__media {
    position: relative;
    overflow: hidden;
    height: $size__thumb;
    @include backgroundFull(center, top);


    
    @include respondTo('xxl') {
      .component--side & {
        max-width: 80%;
      }
    }
  }
  &__image {
    display: none;
  }
  &__name {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__title,
  &__focus {
    color: $color__tan;
    font-size: $fontsize__6;
  
    & + &  { margin-left: .5em; }
  }
  &__phone {
    color: inherit;
  }
  &__practice {
    font-size: $fontsize__6;
    margin-right: .5em;
    &:not(:last-child){
      &:after {
        display: inline-block;
      }
    }

  }
  &__meta {
    font-size: $fontsize__6;
    margin-right: .5em; 

    &:not(:last-child) {
      &:after {
        display: inline-block;
        content: "|";
        margin-left: .5em; 
      }
    }
  }
  &__row { 
    &--img {
      @include no-print;
    }
   }
}