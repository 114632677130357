// BASE SIZE
// --------------------------------------------------
//
$base: 16;
$basepx: $base * 1px;

@function nUnit($s) {
	@if (unitless($s)) {
    $s: $s * 1px;
	}
	@return $s;
}

@function em($px, $bs: $base) {
	@return calc(nUnit($px) / nUnit($bs)) * 1em;
}

@function rem($px, $bs: $base) {
	@return calc(nUnit($px) / nUnit($bs)) * 1rem;
}

$size:			rem($basepx) !default;
$size__md:	$size * 2;
$size__lg:	$size * 3;
$size__xl:	$size * 4;
$size__xxl:	$size * 5;
$size__xxxl:	$size * 6;

// Grid system
// --------------------------------------------------
//## Define your custom responsive grid.
$grid__gutter: $size__xl;

@function containerSize($size) {
  @return $size;
}

// Media queries breakpoints
// --------------------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
$sw--xxs:				375; // Extra small screen / phone
$sw--xs:				480; // Extra small screen / phone
$sw--sm:				768; // Small screen / tablet
$sw--md:				992; // Medium screen / desktop
$sw--lg:				1200; // Large screen / wide desktop
$sw--xl:				1366; // Extra Large screen / wide desktop
$sw--xxl:				1440; // Double Extra Large screen / wide desktop

$screen--xxs:		$sw--xxs  * 1px !default;
$screen--xs:		$sw--xs  * 1px !default;
$screen--sm:		$sw--sm  * 1px !default;
$screen--md:		$sw--md  * 1px !default;
$screen--lg:		$sw--lg  * 1px !default;
$screen--xl:		$sw--xl  * 1px !default;
$screen--xxl:		$sw--xxl * 1px !default;

// Media queries breakpoints
// --------------------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
$size__break--none:  em(0px);
$size__break--xt:  em($screen--xxs);
$size__break--ty:  em($screen--xs);
$size__break--sm:  em($screen--sm);
$size__break--md:  em($screen--md);
$size__break--lg:  em($screen--lg);
$size__break--xl:  em($screen--xl);
$size__break--xxl: em($screen--xxl);

$breakpoints: (
  'none': $size__break--none, //0px
  'xt':   $size__break--xt, //375px
  'ty':   $size__break--ty, //480px
  'sm':   $size__break--sm, //767px
  'md':   $size__break--md, //992px
  'lg':   $size__break--lg, //1200px
  'xl':   $size__break--xl, //1366px
  'xxl':  $size__break--xxl //1440px
) !default;

@mixin respondTo($min: 'md', $max: null) {
  // If the key exists in the map
  @if ($max != null) {
    @if map-has-key($breakpoints, $min) and map-has-key($breakpoints, $max)   {
      @media screen and (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max) - (calc(1/$base) * 1em)) {
        @content;
      }
    }
    @else {
      @warn "One or more breakpoints `#{$min}` or  `#{$max}` do not exist."
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }
  @else {
    @if map-has-key($breakpoints, $min) {
      @media screen and (min-width: map-get($breakpoints, $min)) {
        @content;
      }
    }
    @else {
      @warn "Breakpoint `#{$min}` does not exist."
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }
}

@mixin respondToReverse($max: null) {
  // If the key exists in the map
  @if ($max != null) {
    @media screen and (max-width: map-get($breakpoints, $max) - (calc(1/$base) * 1em)) {
        @content;
    }
  }
}

// @include respond-to-print()
@mixin respond-to-print() {
  @media print {
    @content;
  }
}

// @include dont-print()
@mixin no-print() {
  @include respond-to-print() {
    display: none !important;
    margin: 0 !important;
  }
}

$size__container--sm: containerSize($screen--sm);
$size__container--md: containerSize($screen--md);
$size__container--lg: containerSize($screen--lg);
$size__container--xl: containerSize($screen--xl);
$size__container--xxl: containerSize($screen--xxl);

$size__sidebar: rem(200px);
$size__sidebar--md: ($size__sidebar);
$size__sidebar--lg: ($size__sidebar);
$size__sidebar--xl: ($size__sidebar);
$size__sidebar--xxl: ($size__sidebar);

$size__contentside--sm: rem(200px);
$size__contentside: rem(300px);
$size__contentside--xl: rem(500px);

$size__header: rem(90px);
$size__header--mobile: rem(60px);

$size__hero--mobile: rem(400px);
$size__hero--shortmobile: 300px;
$size__hero: rem(355px);
$size__hero--md: rem(400px);

$size__tile: rem(430px);

$size__articleimg: rem(160px);
$size__articleimg--mobile: rem(200px);
$size__articleimg--xl: rem(200px);

$size__tile--sm: calc($size__tile / 1.5);
$size__tile--ty: calc($size__tile / 2.5);
$size__tile--xt: calc($size__tile / 3.5);

$size__input: 1.2em;

$size__searchicon: $size;

$size__thumb: rem(200px);

$size__spotlightside--md: rem(135px);
$size__spotlightside: rem(200px);